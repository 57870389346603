@font-face {
  font-family: 'Stoke';
  src: local('Stoke'), url(../src/assets/Stoke-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Bandido';
  src: local('Bandido'), url(../src/assets/The_Bandido.otf) format('opentype');
}

body {
  margin: 0;
  font-family: 'Bandido', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #663770;
  background-image: url('../src/assets/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  padding: 2.5vw;
}

p {
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.5;
}

h1 {
  margin: 0px;
  max-width: 750px;
  padding: 50px;
}

.bodyContainer {
  max-width: 750px;
  padding: 2.5vw;
  background-image: url('../src/assets/bodyContainerBackground.png');
  background-size: 100% 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  width: 100%;
  font-family: 'Stoke';
}

.h1Img {
  height: auto;
  width: 100%;
}

.h1Subheader {
  font-size: 3rem;
  text-transform: capitalize;
  font-family: 'Bandido';
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.formLabel {
  font-size: 1.3rem;
  margin-right: 8px;
  margin-left: 8px;
  margin-bottom: 0px;
  text-align: center;
}

.formGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-image: url('../src/assets/textBoxShort.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 8px;
  margin: 8px;
  width: 100%;
}

.formGroupTextArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background-image: url('../src/assets/textBoxTall.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 8px;
  margin: 8px;
  width: 100%;
}

.textControl {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  width: 100%;
}

.submitButton {
  width: 50%;
  background-image: none;
  background-color: #663770;
  color: white;
  border-color: white;
  font-family: 'Bandido';
  letter-spacing: .2em;
  /* margin-right: 25%;
  margin-left: 25%; */
}

.submitButton:hover {
  width: 50%;
  background-image: url('../src/assets/background.jpg');
  border-color: #663770;
  color: #663770;
  letter-spacing: .2em;
}

.links {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 375px;
}

.breakWord {
  word-break: break-all;
}